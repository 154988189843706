@import "mixins/banner";
@include bsBanner("");

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "functions";

// 2. Include any default variable overrides here
$enable-responsive-font-sizes: true;
/*** Color ***/
$min-contrast-ratio: 2.5;
// Brand
$main: #F77C82;
$sub: #3F89F6;
$perl-1: #F5F9FF;
$perl-2: #FFF1F1;
// Component
$stripe: #625BFF;
$line: #EDEDE0;
$line-medium: #E0E0E0;
$background-gray: #F5F5F5;
$background-blue: #F3F6FF;
$accent: #44B5D9;
$accent-deep: #0DA7D8;
$accent-light: #D9F6FF;
$accent-line: #BEE3EE;
$accent-2: #8080F4;
$accent-2-light: #EDEDFF;
$alert: #EB5757;
// Text
$text-black: #414C55;
$text-sub: #828282;
$text-link: #B7B7B7;
//
$primary: $main;
$secondary: $sub;

$body-color: $text-black;
$list-group-action-hover-color: $text-black;
$border-color: $line-medium;

/*** Font ***/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font-family-sans-serif: "Exo 2", "Noto Sans JP", -apple-system, blinkmacsystemfont, "Segoe UI", "Hiragino Kaku Gothic ProN", "BIZ UDPGothic", meiryo, sans-serif;

$font-weight-light: 400;
$font-weight-normal: 500;
$font-weight-medium: 600;
$font-weight-semibold: 700;
$font-weight-bold: 800;

// $h1-font-size: 2.0rem;
// $h2-font-size: 2.0rem;
// $h3-font-size: 1.5rem;
// $h4-font-size: 1.5rem;
// $h5-font-size: 1.0rem;
// $h6-font-size: 1.0rem;


/*** Size ***/
$spacer: 1.0rem;

// 3. Include remainder of required Bootstrap stylesheets (including any separate color mode stylesheets)
@import "variables";
@import "variables-dark";

// 4. Include any default map overrides here
$theme-colors: (
    "main": $main,
    "sub": $sub,
    "perl-1": $perl-1,
    "perl-2": $perl-2,
    "stripe": $stripe,
    "line": $line,
    "line-medium": $line-medium,
    "background-gray": $background-gray,
    "background-blue": $background-blue,
    "accent": $accent,
    "accent-deep": $accent-deep,
    "accent-light": $accent-light,
    "accent-line": $accent-line,
    "accent-2": $accent-2,
    "accent-2-light": $accent-2-light,
    "alert": $alert,
    "text-black": $text-black,
    "text-sub": $text-sub,
    "text-link": $text-link,

    "success": $accent,
    "error": $alert,
);

$tooltip-bg: $accent;
$tooltip-color: lighten($accent, 55%);

$spacers: (
    0: calc($spacer * 0),
    1: calc($spacer * 1),
    2: calc($spacer * 2),
    3: calc($spacer * 3),
    4: calc($spacer * 4),
    5: calc($spacer * 5),

    25: calc($spacer * 0.25),
    50: calc($spacer * 0.5),
);

$card-spacer-x: map-get($spacers, 2);
$card-spacer-y: map-get($spacers, 2);

// 5. Include remainder of required parts
@import "maps";
@import "mixins";
@import "utilities";

// 6. Optionally include any other parts as needed
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";
@import "helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "utilities/api";

// 8. Add additional custom code here

th {
    color: $text-black !important;
}

p,
td {
    color: $text-sub !important;
}

h2,
h4,
h6 {
    font-weight: $font-weight-normal;
}

th {
    font-weight: $font-weight-normal;
}

.card-button-1,
.card-button-2 {
    border: 0;
    background-color: $background-gray;

    padding-left: 0;
    padding-right: 0;
}

.card-button-1:hover {
    background-color: darken($background-gray, 15%);
}

.card-button-2:hover {
    background-color: $main;

    h5,
    p,
    small {
        color: white !important;
    }
}

.card-button-active {
    background-color: $main !important;

    h5,
    p,
    small {
        color: white !important;
    }
}

.dropdown-menu {
    border-radius: 0.375rem !important
}

.callout-perl-1 {
    border-radius: $border-radius;
    border-color: $perl-1;

    background-color: $perl-1;
    color: $text-sub;

    padding: 1rem;
}