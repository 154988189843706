@import './bootstrap-5.3.3/scss/bootstrap';

html {
    font-size: 0.9rem;

    @include media-breakpoint-up(md) {
        font-size: 1.0rem;
    }

    //For mobile
    // @include media-breakpoint-down(md) {
    //     padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
    // }
}

body {
    word-break: break-all;
}

.firebase-emulator-warning {
    display: none;
}

.btn-circle {
    height: 2.0rem;
    width: 2.0rem;
    border-radius: 1.0rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.content {
    max-width: 652px;
}

i {
    width: 1.0rem;
    margin-right: 0.5rem;
    text-align: center;
}

.icon-prefix {
    margin-left: 0;
    margin-right: 0.5rem;
}

.icon-suffix {
    margin-left: 0.5rem;
    margin-right: 0;
}

button i {
    margin-right: 0.5rem;
}

.offcanvas-body {
    padding: 0;
}

ul,
li,
.list-group-item {
    border-radius: 0 !important;
}

p {
    margin-bottom: 0;
}

// Table
th {
    width: 30%;
}

.btn-floating {
    position: absolute;
    top: 0;
    right: 0;

    //transform: translate(0, -50%);
    z-index: 2;
}

img {
    max-width: 100%;
}

.btn-link {
    i {
        margin: 0;
    }
}

.shadow {
    box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.75);
}

a,
.btn-link {
    text-decoration: none;
}

img {
    image-rendering: optimizeQuality;
}

#toast div div {
    padding-bottom: env(safe-area-inset-bottom);
}

.transform-for-ios {
    height: 2.5rem;

    @include media-breakpoint-down(md) {
        height: 10.0rem;
        transform: scale(0.35);
    }
}